<style lang="scss">
    .link-like {
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            &:hover {
                text-decoration: none;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
</style>

<template>
    <span @click="!disabled && $emit('click')" :class="`${disabled ? 'disabled' : ''} link-like`"><slot></slot></span>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";

    export default {
        data() {
            return {};
        },
        props: {
            disabled: Boolean,
        },
        computed: {},
        components: {},
        methods: {},
        beforeMount() {},
        mounted() {},
        created() {}
    };
</script>
