<style lang="scss">
    .formViewItem {
    }
</style>

<template>
    <span class="formViewItem" v-if="!link">
        <div class="label" v-if="label" :style="{textAlign: center ? 'center' : ''}">{{label}}</div>
        <p :style="{fontWeight: bold ? 'bold' : '', textAlign: center ? 'center' : ''}">{{value}}</p>
    </span>
    <span class="formViewItem" v-else>
        <div class="label" v-if="label" :style="{textAlign: center ? 'center' : ''}">{{label}}</div>
        <router-link :to="url" :style="{fontWeight: bold ? 'bold' : '', textAlign: center ? 'center' : ''}">{{value}}</router-link>
    </span>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";

    export default {
        data() {
            return {};
        },
        props: {
            label: {},
            value: {},
            bold: {type: Boolean},
            center: Boolean,
            link: Boolean,
            url: {},
        },
        computed: {},
        components: {},
        methods: {},
        beforeMount() {},
        mounted() {},
        created() {}
    };
</script>
