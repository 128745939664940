<script lang="ts">
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import logo from "@/components/logo.vue";
    import loginModal from "@/components/modals/loginModal.vue";
    import forgotModal from "@/components/modals/forgotModal.vue"
    import resetModal from "@/components/modals/resetModal.vue"
    import {State, Mutation} from "vuex-class";

    @Component({
        name: "xheader",
        components: {
            logo,
            loginModal,
            forgotModal,
            resetModal
        },
        props: {
            reachedTop: Boolean,
            opacityValue: {
                type: Number,
                default: 0
            }
        }
    })
    export default class xheader extends Vue {
        reachedTop!: boolean;
        displayLogin: boolean = false;
        displayForgot: boolean = false;
        displayReset: boolean = false;

        @State('isAuth') isAuth!: any;

        @Mutation('logout') logout: any;

        @Watch('$route.fullPath')
        onChange() {
            if (this.$route.name === 'reinit') {
                this.openReset();
            }
        }

        get textConnexion() {
            return this.isAuth ? 'Déconnexion' : 'Connexion'
        }

        openLogin() {
            if (this.isAuth) {
                this.logout();
                this.$router.push({name: 'home'});
            } else {
                this.displayLogin = true;
                this.$nextTick(() => {
                    this.$modal.show('login');
                });
            }
        }

        openReset() {
            if (!this.isAuth) {
                this.displayReset = true;
                this.$nextTick(() => {
                    this.$modal.show('reset');
                });
            } else {
                this.$router.push({name: 'home'});
            }
        }

        forgotPassword() {
            this.displayLogin = false;
            this.displayReset = false;
            this.$modal.hide('reset');
            this.$modal.hide('login');
            this.displayForgot = true;
            this.$nextTick(() => {
                this.$modal.show('forgot');
            })
        }

        remember() {
            this.displayForgot = false;
            this.displayReset = false;
            this.$modal.hide('forgot');
            this.$modal.hide('reset');
            this.displayLogin = true;
            this.$nextTick(() => {
                this.$modal.show('login');
            })
        }

        beforeMount() {
            this.$root.$on('openLogin', () => {
                this.openLogin();
            });
        }
    }
</script>
<template>
    <div :class="{xheader: true, reachedTop}" :style="reachedTop && {background: `rgba(255, 255, 255, ${opacityValue})`}">
        <div class="flex-wrapper">
            <div class="head-item logo">
                <logo :white="reachedTop" />
                <router-link tag="div" :to="{name: 'home'}" class="projectName behind">
                    {{$t('projectName')}}
                </router-link>
            </div>
            <div class="head-item projectName side">
                {{$t('projectName')}}
            </div>
            <div class="head-item login" @click="openLogin">
                <div class="connect">
                    <span>{{textConnexion}}</span>
                    <div class="icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink"
                             viewBox="0 0 25.23 30" xml:space="preserve">
                        <g>
                            <path d="M12.62,15.13c-4.17,0-7.56-3.39-7.56-7.57C5.05,3.39,8.45,0,12.62,0s7.56,3.39,7.56,7.56
                                C20.18,11.74,16.79,15.13,12.62,15.13z M12.62,2.14c-2.99,0-5.43,2.43-5.43,5.43s2.43,5.43,5.43,5.43s5.43-2.43,5.43-5.43
                                S15.61,2.14,12.62,2.14z"/>
                            <path d="M25.23,30H0l0.03-1.09c0.17-6.9,5.7-12.3,12.59-12.3s12.42,5.4,12.59,12.3L25.23,30z M2.24,27.86h20.74
                                c-0.66-5.19-5.02-9.12-10.37-9.12S2.9,22.67,2.24,27.86z"/>
                        </g>
                    </svg>

                    </div>
                </div>
            </div>
        </div>
        <login-modal @forgotPassword="forgotPassword" v-if="displayLogin" @close="displayLogin = false" />
        <forgot-modal @remember="remember" v-if="displayForgot" @close="displayForgot = false" />
        <reset-modal v-if="displayReset" @connect="displayLogin" @closed="displayReset = false"/>
    </div>
</template>
<style lang="scss">
    .xheader {
        height: 90px;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        background: white;
        transition: background 222ms ease;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

        .projectName {
            cursor: default;
            font-size: 19px;
            font-weight: 400;
            color: $primary;
            margin-top: $gap / 5;

			&.behind {
				display: none;
			}
        }

        .flex-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 $gap / 2;
            max-width: $max-width;
            margin: 0 auto;
			@media (min-width: 1000px) {
                padding: 0 $gap;
            }
        }

        .head-item {
            height: 100%;
            text-transform: uppercase;
            cursor: pointer;
            min-width: 30%;

            &.logo {
                display: flex;
                flex-direction: column;
                justify-content: center ;
                img {
                    height: 45px;
                    margin-top: -7px;
                }

                @media (max-width: 400px) {
                    img {
                        height: 50px;
                    }
                }
            }

            &.login {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: opacity 175ms ease;
                .connect {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    transition: opacity 175ms ease;
                    margin-top: -30px;
                }

                &:hover {
                    opacity: 0.7;
                }

                span {
                    padding-top: 1px;
                    font-weight: $light;
                    font-size: 14px;
                    color: $primary;
                    padding-right: $gap / 4;
                    transition: color 125ms ease;
                }

                .icon {
                    height: 14px;
                    svg {
                        transition: fill 125ms ease;
                        fill: $primary;
                    }
                }
            }
        }

        &.reachedTop {
            background: transparent;
            box-shadow: none !important;
            .projectName {
                color: white;
            }
            .login {
                span {
                    color: white;
                }
                .icon {
                    svg {
                        fill: white;
                    }
                }
            }
        }

		@media (min-width: 1000px) {
            padding-top: 60px;
            padding-bottom: 60px;

            .projectName {
                font-size: 28px;
                text-align: center;
                margin-top: 0;
                height: unset;
                width: 100%;
                font-weight: $light;
            }

            .logo {
                height: 80px !important;
            }

            .head-item.login .connect {
                margin-top: 0;
            }
            .head-item.login span {
                font-size: 28px;
            }
            .head-item.login .icon {
                height: 28px;
            }
        }

		@media (max-width: 1000px) {
			.projectName {
				&.side {
					display: none;
				}
				&.behind {
					display: block;
				}
			}
		}
    }
</style>