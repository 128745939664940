<style lang="scss">
    .text-error {
        margin-top: 10px;
        color: red;
        &.global{
            margin-top: 0;
        }
        & > div {
        }
    }
</style>

<template>
    <div ref="err" :class="`text-error${globalError ? ' global' : ''}`" v-if="error">
        <div v-if="action && error.field">{{action[error.field] || action._default }}</div>
        <div v-else-if="action && error.action">{{action || action._default }}</div>
        <div v-else-if="field">{{field}}</div>
        <div v-else>{{messages && messages[error.slug] && messages[error.slug]._default}}</div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        data() {
            return {
                messages: {
                    gdu_cgv_must_be_true: {
                        _default: "Vous devez accepter cette mention.",
                    },
                    "duplicate_entry": {
                        _default: "Cette valeur existe déjà.",
                        email: "Email déjà utilisé. Veuillez choisir une adresse différente."
                    },
                    "not_found": {
                        _default: "Non existant.",
                        email: "L'adresse email et le mot de passe fourni ne semble pas correspondre.",
                        token: "Opération invalide: identification manquante.",
                        code: "Ce code ne correspond pas à la coopérative choisie.",
                        "/auth/auth-new-password": {
                            _default: "Mauvais lien de récupération de mot de passe, veuillez refaire le procécus en entier.",
                        }
                    },
                    "missing": {
                        _default: "Champs obligatoire."
                    },
                    "bad_value": {
                        _default: "Format incorrect.",
                        email: "Format d'email incorrect.",
                        'cooperative.code': "Ce code ne semble pas correspondre avec l'organisme choisie.",
                        certify: "Vous devez accepter cette mention.",
                        cooperativeId: "Vous devez choisir un organisme stockeur."
                    },
                    "dont_match": {
                        _default: "Les mots de passe sont différent."
                    },
                    "empty": {
                        _default: "Ce champs est obligatoire.",
                        "/eshop-customer/password/change": "Veuillez renseigner un mot de passe valide."
                    },
                    cooperative_email_or_phone_mandatory: {
                        _default: "Vous devez au moins renseigner votre email ou téléphone.",
                    },

                    // news

                    "empty.string": {
                        _default: "Champs obligatoire"
                    },

                    "bad.email": {
                        _default: "Mauvais format d'email."
                    },

                    "bad.password.format": {
                        _default: "Votre mot de passe doit contenir au moins 8 caractères, 1 minuscule, 1 majuscule 1 chiffre"
                    },

                    "required": {
                        _default: "Champs obligatoire"
                    },
                    "notValidEmail": {
                        _default: "Email existant ou invalide."
                    },
                    "notValidResetEmail": {
                        _default: "Email inexistant ou invalide."
                    },
                    notValidReset: {
                        _default: "Le renouvelement de votre mot de passe a expiré."
                    },
                    notSame: {
                        _default: "Les mots de passes ne correspondent pas."
                    },
                    wrongLogin: {
                        _default: "Email et/ou mot de passe incorrects.",
                    },
                    needRights: {
                        _default: "Vous devez accepter la cession de votre image."
                    },
                    needCgu: {
                        _default: "Vous devez accepter les conditions générales d'utilisation."
                    },
                    tooLowDuration: {
                        _default: "Votre capture n'est pas assez longue, elle doit être d'au minimum 8 secondes et au maximum 30 secondes."
                    },
                    tooHighDuration: {
                        _default: "Votre capture est trop longue, elle doit être d'au minimum 8 secondes et au maximum 30 secondes."
                    },
                    "nomedium-image": {
                        _default: "Vous devez joindre votre dessin pour pouvoir participer."
                    },
                    "nomedium-video": {
                        _default: "Vous devez joindre ou capturer une vidéo pour pouvoir participer."
                    },
                    tooShort: {
                        _default: "Votre mot de passe doit contenir au moins 8 caractères."
                    },
                    cannotCreateMediaRecorder: {
                        _default: "Il semble que votre navigateur ne soit pas en capacité de capturer une vidéo à partir de votre webcam."
                    },
                    noDevices: {
                        _default: "Il semble que vous n'avez pas de caméra disponible."
                    },
                    noMediaRecorder: {
                        _default: "Il semble que votre navigateur ne soit pas en mesure de capturer une vidéo avec votre caméra. Veuillez réessayer avec un autre navigateur tel que Google Chrome ou Firefox."
                    },
                    wrongImageType: {
                        _default: "Vous ne pouvez envoyer que des images au format JPEG ou PNG."
                    },
                    imageBroken: {
                        _default: "Il semble que l'image envoyée n'est pas lisible."
                    },
                    "global-participation-error": {
                        _default: "Une erreur est survenue durant l'enregistrement de votre participation. Veuillez réessayer dans quelques secondes."
                    }
                }
            };
        },
        props: {
            error: Object,
            globalError: Boolean,
            noScroll: Boolean
        },
        watch: {
            error(n) {
                console.log("ERROR = ", n);
                if (!this.noScroll && !this.globalError) {
                    this.scrollTop();
                }
            }
        },
        computed: {
            ...mapState(["scrollActivated"]),
            action() {
                return this.messages[this.error.slug] && this.messages[this.error.slug][this.error.action];
            },
            field() {
                return this.messages[this.error.slug] && this.messages[this.error.slug][this.error.field]
            }
        },
        methods: {
            ...mapMutations(["setScroll"]),
            scrollTop() {
                console.log(this.scrollActivated);
                if (!this.scrollActivated) {
                    this.setScroll(true);
                    this.$scrollTo(this.$refs.err, 500, {
                        offset: -300,
                        onDone: () => {
                            this.setScroll(false);
                        },
                        onCancel: () => {
                            this.setScroll(false);
                        }
                    });
                    // this.scrollTo(this.$refs.err.offsetTop - 400);
                    //
                    // const body = $("html, body");
                    // const err = $(this.$refs.err);
                    //
                    // body.animate({ scrollTop: err.offset().top - 400 }, _ => {
                    //     this.setScroll(false);
                    // });
                }
            }
        },
        mounted() {
            if (!this.noScroll && !this.globalError) {
                this.scrollTop();
            }
        }
    };
</script>
