<script lang="ts">
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {Carousel, Slide} from "vue-carousel";

    const partnerImageDir = "/static/imgs/partners/";

    @Component({
        name: "partners",
        components: {
            Carousel,
            Slide
        }
    })
    export default class partners extends Vue {
        $isMobile!: boolean;
        $isTablet!: boolean;

        currentIndex:number = 0;

        getPartnerImg(image: string) {
            return partnerImageDir + image;
        }

        navigateLeft() {
            this.currentIndex -= 1;
            this.currentIndex = this.currentIndex < 0 ? 0 : this.currentIndex;
        }

        navigateRight() {
            this.currentIndex += 1;
            // @ts-ignore
            this.currentIndex = ((this.partners.length / this.perPage) <= this.currentIndex) ? 0 : this.currentIndex;
        }

        get partners() {
            return this.$t('partners');
        }

        get perPage() {
            return this.$isMobile ? 2 : this.$isTablet ? 3 : 4;
        }
    }
</script>
<template>
    <div class="partners">
        <div class="partner" v-for="(partner, index) in partners" :key="index">
            <img :src="getPartnerImg(partner.image)" :alt="partner.name">
        </div>
<!--        <div class="icon left" @click="navigateLeft">-->
<!--            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                 viewBox="0 0 13.47 22.9" xml:space="preserve">-->
<!--                <path d="M11.42,22.89c-0.51,0-1.02-0.2-1.41-0.59l-9.42-9.42c-0.78-0.78-0.78-2.05,0-2.83l9.47-9.47-->
<!--	c0.78-0.78,2.05-0.78,2.83,0c0.78,0.78,0.78,2.05,0,2.83l-8.06,8.06l8.01,8.01c0.78,0.78,0.78,2.05,0,2.83-->
<!--	C12.45,22.7,11.94,22.89,11.42,22.89z"/>-->
<!--            </svg>-->
<!--        </div>-->
<!--        <div class="carousel">-->
<!--            <carousel :touchDrag="false" :mouseDrag="false" :per-page="perPage" :loop="true" infinite :paginationEnabled="false" :navigateTo="currentIndex" :speed="1500">-->
<!--                <slide class="partner" v-for="(partner, index) in partners" :key="index">-->
<!--                    <a ><img :src="getPartnerImg(partner.image)" alt=""></a>-->
<!--                </slide>-->
<!--            </carousel>-->
<!--        </div>-->
<!--        <div class="icon right" @click="navigateRight">-->
<!--            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                 viewBox="0 0 13.5 22.9" xml:space="preserve">-->
<!--                <path d="M2.1,0c0.5,0,1,0.2,1.4,0.6l9.4,9.4c0.8,0.8,0.8,2.1,0,2.8l-9.5,9.5c-0.8,0.8-2.1,0.8-2.8,0-->
<!--	c-0.8-0.8-0.8-2.1,0-2.8l8.1-8.1l-8-8c-0.8-0.8-0.8-2,0-2.8C1,0.2,1.5,0,2.1,0z"/>-->
<!--            </svg>-->
<!--        </div>-->
    </div>
</template>
<style lang="scss">
    .partners {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $gap / 2 $gap / 4;
        box-shadow: 0px -10px 10px 0px rgba(0,0,0,0.15);
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .icon {
            height: 30px;
            width: 30px;
            margin: 0 auto;
            cursor: pointer;
            svg {
                fill: $primary;
            }
        }

        .partner {
            text-align: center;
            width: 25%;
            padding: 7px;
            img {
                height: 100px;
                width: 100%;
                object-fit: contain;
                max-width: 200px;
            }
        }

        .carousel {
            max-width: 70%;
            margin-left: $gap / 2;
            margin-right: $gap / 2;
        }

        @include mobile {
            .VueCarousel-slide {
                visibility: visible;
                flex-basis: 49.8%;
            }
        }

        @include tabletMin() {
            padding: $gap;

            .carousel {
                max-width: unset;
                width: 100%;
            }
        }
    }
</style>