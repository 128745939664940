<style lang="scss">
    $height: 36px;
    .inputInlineText {
        height: $height;

        .input-flex-wrapper {
            display: flex;
            align-items: center;
            height: 100%;
        }

        input {
            padding: $gap / 4 0;
            padding-left: $gap / 2;
            border: 3px solid;
        }

        .action {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: $bold;
            padding: 0 $gap / 1.5;
            line-height: $height;
            height: 100%;
            border-radius: 50px;
            cursor: pointer;
        }

        &.left {
            input {
                border-left: 0;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
            }

            .action {
                margin-right: -$gap / 2;
            }
        }

        &.right {
            input {
                border-right: 0;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
            }

            .action {
                margin-left: -$gap / 2;
            }
        }

        &.yellow {
            input {
                border-color: $secondary;
            }
            .action {
                background: $secondary;
            }
        }
        &.blue {
            input {
                border-color: $primary;
            }
            .action {
                background: $primary;
            }
        }
        &.error {
            input {
                border-color: red;
            }
        }
    }

</style>

<template>
    <form-group :error="error">
        <div :class="{
                    inputInlineText: true,
                    error,
                    right,
                    left,
                    blue,
                    yellow
                }">
            <div class="input-flex-wrapper">
                <div v-if="left" class="action" @click="$emit('submit')">{{actionLabel}}</div>
                <div class="label" @click="$refs.input.focus()"></div>
                <input
                    ref="input"
                    :id="name"
                    v-model="hasMask ? displayedValue : cur_value"
                    @input="$emit('input', cur_value)"
                    :maxlength="length"
                    :name="name"
                    :type="type"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :value="cur_value"
                    :pattern="pattern"
                    :required="required"
                    :min="min"
                    :max="max"
                    :step="step"
                    :disabled="disabled"
                    @keydown.enter="$emit('enter')"
                >
                <div v-if="right" class="action" @click="$emit('submit')">{{actionLabel}}</div>
            </div>
        </div>
    </form-group>
</template>

<script>
    export default {
        name: "inputInlineText",
        $_veeValidate: {
            value() {
                return this.cur_value || this.selectValue;
            },
            name() {
                return this.label;
            }
        },
        data() {
            return {
                cur_value: "",
                select_value: "",
                displayedValue: "",
            };
        },
        watch: {
            select_value(n) {
                this.$emit("selectChange", n);
            },
            value(n) {
                this.cur_value = n;
                if (this.hasMask) {
                    this.displayedValue = "~ " + n + "t";
                }
            }
        },
        props: {
            actionLabel: String,
            hasMask: Boolean,
            right: Boolean,
            left: Boolean,
            blue: Boolean,
            yellow: Boolean,
            min: {},
            max: {},
            label: {},
            name: {},
            value: {},
            type: {},
            step: {},
            placeholder: {},
            disabled: Boolean,
            withSelect: { type: Boolean },
            items: {},
            innerText: {},
            selectItems: { type: Array },
            readonly: { type: Boolean },
            selectValue: {},
            required: { type: Boolean },
            pattern: {},
            length: {},
            error: {},
            canEmptyField: {type: Boolean},
            sublabel: {}
        },
        computed: {},
        components: {},
        methods: {
            emptyField() {
                this.cur_value = '';
                this.$emit('input', this.cur_value);
            }
        },
        beforeMount() {},
        updated() {
            if (typeof this.value !== "undefined") {
                this.cur_value = this.value;
            }
            if (typeof this.selectValue !== "undefined") {
                if (this.select_value === this.selectValue) {
                    this.$emit("selectChange", this.selectValue);
                }
                this.select_value = this.selectValue;
            }
            if (this.hasMask) {
                this.displayedValue = "~ " + this.cur_value + "t";
            }
        },
        mounted() {
            if (typeof this.value !== "undefined") {
                this.cur_value = this.value;
            }
            if (typeof this.selectValue !== "undefined") {
                if (this.select_value === this.selectValue) {
                    this.$emit("selectChange", this.selectValue);
                }
                this.select_value = this.selectValue;
            }
            if (this.hasMask) {
                this.displayedValue = "~ " + this.cur_value + "t";
            }
        },
        created() {}
    };
</script>
