<style lang="scss">
    .btn.primary {
        cursor: pointer;
        padding: $gap / 2 $gap;
        border: 2px solid $primary;
        border-radius: 50px;
        width: auto;
        display: inline-block;
        margin-top: $gap;
        font-weight: $bold;
        color: $primary;

        &.yellow {
            svg path {
                fill: $yellow;
            }
            border-color: $yellow;
        }

        &.yellow-border {
            border-color: $yellow;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &.icon {
            display: inline-flex;
            align-items: center;
            padding: $gap / 1.5;
            svg path {
                fill :$primary;
            }
            .icon {
                margin-right: $gap / 3;
            }
        }

        @include tabletMin() {
            padding: $biggap / 2 $biggap;
        }
    }
</style>

<template>
    <router-link tag="div" v-if="to" :to="to" @click="$emit('click')" :class="`btn primary ${icon ? 'icon' : ''} ${disabled ? 'disabled' : ''}`">
        <icon v-if="icon" :icon="icon" />
        <slot/>
    </router-link>
    <div v-else @click="!disabled && $emit('click')" :class="`btn primary ${icon ? 'icon' : ''} ${disabled ? 'disabled' : ''}`">
        <icon v-if="icon" :icon="icon" />
        <slot/>
    </div>
</template>

<script>
    import icon from "@/components/icon.vue"

    export default {
        data() {
            return {};
        },
        props: ["to", "disabled", "icon"],
        computed: {},
        components: {
            icon
        },
        methods: {},
        beforeMount() {},
        mounted() {},
        created() {}
    };
</script>
