<style lang="scss">
    .col {
    }
</style>

<template>
    <div :class="`col${w ? ' col-wide-' + w : ''}${d ? ' col-desktop-' + d : ''}${t ? ' col-tablet-' + t : ''}${m ? ' col-mobile-' + m : ''}${ow ? ' offset-' + ow : ''}${od ? ' offset-desktop-' + od : ''}${ot ? ' offset-tablet-' + ot : ''}${om ? ' offset-mobile-' + om : ''}`">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            w: {
                type: String,
                default: ''
            },
            d: {
                type: String,
                default: ''
            },
            t: {
                type: String,
                default: ''
            },
            m: {
                type: String,
                default: '12'
            },
            ow: {
                type: String,
                default: ''
            },
            od: {
                type: String,
                default: ''
            },
            ot: {
                type: String,
                default: ''
            },
            om: {
                type: String,
                default: ''
            },
        }
    };
</script>
