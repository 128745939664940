<script lang="ts">
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "icon",
        props: {
            icon: String
        }
    })
    export default class icon extends Vue {
    }
</script>
<template>
    <div class="icon" v-if="icon">
        <template v-if="icon === 'edit'">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 40.03 40" xml:space="preserve">
                    <path d="M39.48,4.18C38.38,1.56,35.82,0,32.63,0c0,0-0.01,0-0.01,0h0c-1.4,0.01-2.99,0.75-4.24,1.97c-0.41,0.4-0.82,0.81-1.23,1.22
                        L4.42,25.92c-0.22,0.22-0.49,0.53-0.63,0.96l-0.65,1.94c-1,3.01-2.01,6.03-3.02,9.04c-0.24,0.71-0.13,1.27,0.32,1.72
                        C0.78,39.91,1.15,40,1.47,40c0.3,0,0.56-0.09,0.69-0.13c3.56-1.19,7.13-2.38,10.7-3.56c0.51-0.17,0.94-0.44,1.32-0.82
                        c4.09-4.1,8.18-8.19,12.27-12.28l5.65-5.64c1.97-1.97,3.94-3.94,5.91-5.91C40.01,9.65,40.57,6.78,39.48,4.18z M9.65,29.46
                        C9.65,29.45,9.65,29.45,9.65,29.46c1.4-1.38,2.79-2.78,4.18-4.17L30.04,9.08l0.12-0.12l0.11,0.11c0.26,0.25,0.51,0.51,0.77,0.77
                        l0.01,0.01L19.3,21.61c-2.9,2.89-5.79,5.79-8.68,8.69c-0.03,0.03-0.05,0.05-0.07,0.07c-0.01-0.01-0.01-0.02-0.02-0.03
                        C10.29,30.06,10.02,29.78,9.65,29.46z M18.19,18.18c-3.28,3.28-6.57,6.57-9.84,9.86c-0.03,0.03-0.05,0.05-0.07,0.07
                        c0-0.01-0.01-0.01-0.01-0.02c-0.16-0.21-0.36-0.4-0.55-0.58l-0.24-0.23c-0.03-0.03-0.06-0.06-0.08-0.08
                        c0.02-0.02,0.04-0.04,0.07-0.07l10.25-10.25c3.39-3.39,6.78-6.77,10.16-10.17c0.01-0.01,0.02-0.02,0.04-0.03c0,0,0,0,0,0
                        c0.2,0.24,0.43,0.46,0.65,0.67l0.23,0.23L18.19,18.18z M18.12,25.52l4.46-4.46c3.27-3.27,6.54-6.54,9.8-9.81
                        c0.02-0.02,0.03-0.03,0.05-0.05c0.01,0.01,0.01,0.02,0.02,0.03c0.2,0.24,0.43,0.46,0.66,0.68l0.21,0.2l-9.38,9.38
                        c-3.69,3.69-7.39,7.39-11.08,11.09c-0.02,0.02-0.03,0.03-0.05,0.04c-0.01-0.01-0.01-0.02-0.02-0.02c-0.23-0.28-0.51-0.56-0.87-0.89
                        C13.99,29.65,16.05,27.59,18.12,25.52z M35.45,9.99c-0.84-0.83-1.67-1.66-2.5-2.5l-1.26-1.27c-0.54-0.54-1.07-1.08-1.67-1.66
                        c0.88-0.93,1.7-1.4,2.65-1.53c1.08-0.15,2.16,0.18,2.98,0.91c0.87,0.77,1.36,1.91,1.35,3.13v0.01C37.02,8.04,36.51,8.99,35.45,9.99z
                         M5.98,34.83c-0.41-0.41-0.82-0.83-1.24-1.3c0.4-1.18,0.8-2.37,1.19-3.56c1.04,1.05,2.08,2.1,3.13,3.14l0.97,0.97l-3.6,1.2
                        c0,0,0-0.01-0.01-0.01L5.98,34.83z M4,35.75c0.02-0.04,0.03-0.08,0.04-0.12l0.34,0.33l-0.52,0.17C3.91,36.01,3.95,35.88,4,35.75z"/>
                </svg>
        </template>
        <template v-if="icon === 'close'">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 40.03 40" style="enable-background:new 0 0 40.03 40;" xml:space="preserve">
<path d="M20,0C8.97,0,0,8.97,0,20s8.97,20,20,20s20-8.97,20-20S31.03,0,20,0z M30.15,27.71c0.68,0.68,0.68,1.77,0,2.45
	c-0.34,0.34-0.78,0.51-1.22,0.51s-0.89-0.17-1.22-0.51L20,22.45l-7.71,7.71c-0.34,0.34-0.78,0.51-1.22,0.51s-0.89-0.17-1.22-0.51
	c-0.68-0.68-0.68-1.77,0-2.45L17.55,20l-7.71-7.71c-0.68-0.68-0.68-1.77,0-2.45c0.67-0.68,1.77-0.68,2.45,0L20,17.55l7.71-7.71
	c0.67-0.68,1.77-0.68,2.45,0c0.68,0.68,0.68,1.77,0,2.45L22.45,20L30.15,27.71z"/>
</svg>

        </template>
        <template v-if="icon === 'remove'">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 37.41 40" style="enable-background:new 0 0 37.41 40;" xml:space="preserve">
<g>
	<path d="M37.22,7.55c-0.33-0.69-0.95-1.07-1.74-1.07h-2.3c-2.04,0-4.07,0-6.13,0.01c0.01-0.66,0.01-1.32,0-1.99l0-0.37
		c-0.01-2.11-2.01-4.11-4.12-4.12C20.11,0,17.29,0,14.48,0.01c-2.11,0-4.11,2.01-4.12,4.12l0,0.26c0,0.69-0.01,1.37,0,2.09
		C7.75,6.47,5.15,6.48,2.54,6.48l-0.62,0C0.85,6.48,0.01,7.29,0,8.32c-0.01,1.07,0.81,1.89,1.91,1.89l0.2,0
		c0.58,0.01,1.16,0.01,1.78,0c-0.01,3.15-0.01,6.31-0.01,9.46l0,2.55l0,3.52c0,2.84,0,5.68,0,8.52c0.01,3.04,2.68,5.72,5.72,5.72
		c2.8,0,5.61,0,8.41,0c3.23,0,6.46,0,9.68,0c3.14,0,5.81-2.67,5.82-5.82c0-1.29,0-2.57,0-3.86l0-3.81c0-5.42,0-10.83-0.01-16.29
		c0.46,0.01,0.92,0.01,1.38,0l0.63,0c0.6,0,1.11-0.24,1.47-0.7C37.46,8.92,37.54,8.22,37.22,7.55z M14.1,4.11
		c0-0.25,0.05-0.32,0.05-0.32c0,0,0.07-0.05,0.32-0.05c2.81,0,5.63,0,8.44,0c0.34,0,0.35,0.04,0.35,0.04c0,0,0.05,0.08,0.05,0.36
		l0,0.85c0,0.49,0,0.98,0,1.49c-0.73-0.01-1.45-0.01-2.18-0.01c-0.41,0-0.81,0-1.22,0l-1.22,0l-1.2,0c-1.11,0-2.23,0-3.38,0
		c0.01-0.48,0.01-0.96,0-1.44L14.1,4.11z M29.78,34.21c0,0.31-0.03,0.57-0.1,0.8c-0.23,0.82-0.84,1.23-1.82,1.24
		c-1.49,0.01-2.97,0.01-4.46,0l-4.4,0l-9.26,0c-0.37,0-0.64-0.03-0.87-0.1c-0.82-0.23-1.24-0.84-1.24-1.82
		c-0.01-1.55-0.01-3.1-0.01-4.64l0-5.02c0-4.8,0-9.6-0.01-14.47c7.37,0.01,14.74,0.01,22.16,0c-0.01,3.18-0.01,6.37-0.01,9.55
		L29.78,34.21z"/>
    <path d="M24.94,15.55c-0.93,0.11-1.64,0.91-1.64,1.86l0,14.14c0,0.56,0.2,1.07,0.57,1.44c0.34,0.34,0.81,0.53,1.31,0.53
		c0,0,0,0,0,0c1.08,0,1.87-0.83,1.87-1.97l0-14.01c0-0.17,0-0.36-0.05-0.55C26.78,16.06,25.9,15.44,24.94,15.55z"/>
    <path d="M18.48,15.55c-0.94,0.11-1.64,0.91-1.64,1.86c0,1.65,0,3.31,0,4.96v9.18c0,1.12,0.8,1.97,1.87,1.97
		c1.08,0,1.87-0.83,1.87-1.97V17.55c0-0.19-0.01-0.38-0.06-0.59C20.28,16.04,19.43,15.45,18.48,15.55z"/>
    <path d="M12,15.55c-0.93,0.11-1.64,0.91-1.64,1.86l0,14.14c0,1.12,0.8,1.97,1.87,1.97c1.08,0,1.87-0.83,1.87-1.97V17.54
		c0-0.19-0.01-0.38-0.06-0.59C13.8,16.03,12.94,15.45,12,15.55z"/>
</g>
</svg>
        </template>
        <template v-if="icon === 'upload'">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 32.15 40" style="enable-background:new 0 0 32.15 40;" xml:space="preserve">
<g>
	<path d="M9.82,13.01c0.62-0.59,1.22-1.21,1.82-1.82c0.5-0.51,1-1.03,1.54-1.59c0.12,0.26,0.09,0.44,0.09,0.6c0,4.98,0,9.96,0,14.94
		c0,0.29,0.02,0.59,0.07,0.88c0.29,1.84,2.36,2.88,4,2.01c1.08-0.57,1.54-1.5,1.54-2.7c-0.01-5.06,0-10.12,0-15.18
		c0-0.15-0.06-0.32,0.06-0.46c0.18,0.04,0.26,0.19,0.38,0.3c1.12,1.09,2.16,2.27,3.35,3.29c1.27,1.09,3.36,0.65,4.15-0.83
		c0.65-1.21,0.43-2.46-0.62-3.51c-2.64-2.64-5.28-5.28-7.92-7.92c-0.23-0.23-0.46-0.45-0.74-0.61c-1.22-0.71-2.52-0.51-3.58,0.55
		c-1.59,1.59-3.18,3.18-4.77,4.77C8,6.92,6.81,8.07,5.68,9.28c-0.8,0.87-0.92,1.93-0.42,2.99C6.11,14.09,8.32,14.44,9.82,13.01z"/>
    <path d="M32.06,32.55c-0.28-1.35-1.53-2.24-2.86-2.15c-1.43,0.1-2.45,1.09-2.62,2.52c-0.03,0.28-0.05,0.56-0.18,0.82
		c-0.31,0.56-0.83,0.66-1.4,0.66c-5.84,0-11.67-0.01-17.51,0.01c-1.18,0-1.81-0.23-1.94-1.65c-0.12-1.38-1.38-2.38-2.75-2.37
		c-1.37,0.01-2.52,1-2.74,2.36c-0.09,0.54-0.02,1.07,0.06,1.6c0.47,3.04,3.23,5.56,6.29,5.61c3.23,0.06,6.45,0.01,9.68,0.01
		c0,0.01,0,0.01,0,0.02c3.03,0,6.05,0.01,9.08,0c2.94-0.02,5.09-1.36,6.38-4.01C32.06,34.91,32.3,33.74,32.06,32.55z"/>
</g>
</svg>

        </template>
        <template v-if="icon === 'document'">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 31.92 40" style="enable-background:new 0 0 31.92 40;" xml:space="preserve">
<g>
	<path d="M31.92,12.07c0-0.39-0.06-0.95-0.53-1.42c-3.42-3.38-6.84-6.78-10.25-10.17C20.82,0.16,20.43,0,19.99,0
		C14.86,0,9.74,0,4.61,0c-0.6,0-1.19,0.12-1.74,0.35C1.05,1.12,0,2.78,0,4.89l0,15.1h0l0,4.48l0,10.75c0,2.77,2.02,4.79,4.81,4.79
		c3.26,0,6.52,0,9.77,0c4.17,0,8.33,0,12.5,0c2.85,0,4.84-1.99,4.84-4.85l0-11.12L31.92,12.07z M28.37,35.3
		c0.01,0.38-0.1,0.69-0.3,0.9c-0.2,0.21-0.51,0.32-0.88,0.32c-1.22,0-2.43,0-3.65,0l-1.46,0l-17.25,0c-0.69,0-1.13-0.29-1.31-0.85
		c-0.04-0.13-0.05-0.29-0.05-0.44l0-15.28l0-15.25c0-0.44,0.14-1.01,0.79-1.24C4.4,3.41,4.56,3.38,4.77,3.38h1.58
		c3.89,0,7.77,0,11.7,0c-0.01,1.86-0.01,3.73,0,5.59c0.01,2.69,2.05,4.72,4.74,4.73c1.84,0.01,3.68,0.01,5.59,0
		c-0.01,5.93-0.01,11.87-0.01,17.8L28.37,35.3z M25.86,10.32c-1.01,0-2.02,0.01-3.03-0.01c-0.75-0.01-1.22-0.48-1.22-1.24l0-1.99
		l0-1.25c1.48,1.49,2.95,2.99,4.42,4.48L25.86,10.32z M26.94,10.55l-0.04,0.26c0,0,0,0,0,0l-0.04-0.34
		C26.89,10.5,26.91,10.52,26.94,10.55z"/>
    <path d="M8.59,20.78c0.01,0,0.01,0,0.01,0c1.79-0.01,3.58-0.01,5.37-0.01l1.92,0l1.42,0c1.99,0,3.98,0,5.97,0.01
		c0.18,0.02,0.42-0.02,0.62-0.22c0.19-0.2,0.21-0.45,0.2-0.62c-0.02-0.62-0.01-1.23,0-1.85c0-0.09,0.01-0.38-0.2-0.59
		c-0.21-0.21-0.5-0.21-0.59-0.21c-0.67,0.01-1.33,0.01-2,0.01l-3.23,0c-3.17,0-6.33,0-9.5-0.01c0,0,0,0,0,0
		c-0.18,0-0.43,0.02-0.63,0.22c-0.22,0.22-0.21,0.52-0.2,0.63c0.02,0.58,0.02,1.16,0,1.74c-0.01,0.18,0.01,0.45,0.21,0.66
		C8.13,20.75,8.39,20.78,8.59,20.78z"/>
    <path d="M23.26,22.96c-1.87,0.01-3.73,0.01-5.6,0.01l-1.72,0v0l-1.66,0c-1.9,0-3.8,0-5.7-0.01c-0.12,0-0.42,0-0.64,0.22
		c-0.19,0.2-0.21,0.46-0.2,0.63c0.02,0.59,0.02,1.18,0,1.78c-0.01,0.18,0.01,0.44,0.21,0.64c0.19,0.19,0.44,0.22,0.62,0.22
		c0.01,0,0.01,0,0.02,0c1.2-0.01,2.41-0.01,3.61-0.01l3.42,0c2.56,0,5.12,0,7.69,0.01c0.2-0.02,0.43-0.02,0.62-0.22
		c0.19-0.2,0.21-0.45,0.2-0.62c-0.02-0.59-0.02-1.19,0-1.78c0.01-0.18-0.01-0.44-0.21-0.64C23.71,22.98,23.45,22.96,23.26,22.96z"/>
    <path d="M23.23,28.61c-1.51,0.01-3.02,0.01-4.54,0.01l-3.68,0c-2.13,0-4.27,0-6.4-0.01c-0.16-0.01-0.46,0.02-0.66,0.23
		c-0.2,0.21-0.22,0.48-0.21,0.67c0.02,0.57,0.02,1.14,0,1.71c-0.01,0.18,0.01,0.45,0.2,0.65c0.2,0.2,0.48,0.22,0.64,0.23
		c1.89-0.01,3.78-0.01,5.67-0.01l3.55,0c1.82,0,3.65,0,5.47,0.01c0.01,0,0.01,0,0.02,0c0.13,0,0.41-0.01,0.61-0.22
		c0.19-0.2,0.21-0.46,0.2-0.63c-0.02-0.58-0.02-1.16,0-1.74c0-0.1,0.02-0.42-0.21-0.66C23.7,28.63,23.45,28.61,23.23,28.61z"/>
</g>
</svg>

        </template>
        <template v-if="icon === 'valid'">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
<path d="M20,0C8.97,0,0,8.97,0,20s8.97,20,20,20s20-8.97,20-20S31.03,0,20,0z M32.27,14.66l-10.2,13.18c-0.41,0.52-1,0.87-1.66,0.95
	c-0.11,0.01-0.21,0.02-0.32,0.02c-0.55,0-1.09-0.18-1.53-0.52l-9.99-7.73c-1.09-0.84-1.29-2.41-0.45-3.5
	c0.84-1.09,2.41-1.29,3.5-0.45l8.01,6.2l8.67-11.2c0.84-1.09,2.41-1.29,3.5-0.45C32.92,12,33.12,13.57,32.27,14.66z"/>
</svg>

        </template>
        <template v-if="icon === 'connexion'">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 25.23 30" style="enable-background:new 0 0 25.23 30;" xml:space="preserve">
<g>
	<path d="M12.62,15.13c-4.17,0-7.56-3.39-7.56-7.57C5.05,3.39,8.45,0,12.62,0s7.56,3.39,7.56,7.56
		C20.18,11.74,16.79,15.13,12.62,15.13z M12.62,2.14c-2.99,0-5.43,2.43-5.43,5.43s2.43,5.43,5.43,5.43s5.43-2.43,5.43-5.43
		S15.61,2.14,12.62,2.14z"/>
    <path d="M25.23,30H0l0.03-1.09c0.17-6.9,5.7-12.3,12.59-12.3s12.42,5.4,12.59,12.3L25.23,30z M2.24,27.86h20.74
		c-0.66-5.19-5.02-9.12-10.37-9.12S2.9,22.67,2.24,27.86z"/>
</g>
</svg>
        </template>
    </div>
</template>
<style lang="scss">
    .icon {
        svg {
            width: 100%;
            height: 100%;
        }
    }
</style>