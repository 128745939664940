"use strict";

import {Commit, Dispatch} from "vuex";
import {IRootState} from "@/types";

function fake(obj: any, key?: string) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (key) {
                resolve({ data: {[key]: obj} });
            } else {
                resolve({ data: obj });
            }
        }, 1000);
    });
}

function request(dispatch: Dispatch, to: string, method: string, data?: any, params?: any) {
    return dispatch(
        "send",
        {
            to,
            method,
            data,
            params
        },
        { root: true }
    );
}

function login(resolve: any, commit: Commit, result: any) {
    localStorage.setItem("token", result.token);
    commit("setToken", result.token, { root: true });
    commit("setLoginVerified", true, { root: true });
    commit("setIsAuth", true, { root: true });
    commit("setUser", result.participation, { root: true });
    resolve(result);
}

const actions = {
    login({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/participation/login", "POST", data || {})
                .then((result: any) => {
                    login(resolve, commit, result);
                })
                .catch((err: any) => {
                    reject(err);
                })
        });
    },
    reauthenticate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/participation/reauthenticate", "POST", data || {})
                .then((result: any) => {
                    if (result && result.token) {
                        login(resolve, commit, result);
                    } else {
                        reject({});
                    }
                })
                .catch((err: any) => {
                    reject(err);
                })
        });
    },
    registerNewsletter({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/newsletter", "POST", data || {});

    },
    sendForgot({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation/forgottenpassword", "POST", data || {});
    },
    resetPassword({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation/resetpassword", "POST", data || {}, {});
    },
    participate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation", "POST", data || {});
    },
    getParticipation({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation", "GET");
    },
    editParticipation({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation", "PUT", data || {});
    },
    removeParticipation({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation", "DELETE");
    },
    getAuthorization({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation/downloadauthorization", "GET");
    },
    getMedium({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/participation/downloadmedium", "GET");
    },
};

export default {
    namespaced: true,
    actions
};
