import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import VueTransitions from "vue2-transitions";
//import VueGtag from "vue-gtag";
import VueMq from 'vue-mq';

import VModal from "vue-js-modal";

import vueScrollTo from "vue-scrollto";

import FormViewItem from "@/components/forms/formViewItem.vue";
import FormGroup from "@/components/forms/formGroup.vue";
import InputText from "@/components/forms/inputText.vue";
import ErrorMessage from "@/components/forms/error.vue";
import BtnPrimary from "@/components/btns/primary.vue";
import Row from "@/components/grids/row.vue";
import Column from "@/components/grids/column.vue";
import LinkLike from "@/components/linkLike.vue";
import Pulse from "@/components/spinners/pulse.vue";
import InputCheckbox from "@/components/forms/checkbox.vue";
import SubTitle from "@/components/subTitle.vue";

import VueMoment from "vue-moment";
import VueMask from 'v-mask';

Vue.use(VueMask);
Vue.component('sub-title', SubTitle);
Vue.component("pulse", Pulse);
Vue.component("link-like", LinkLike);
Vue.component("row", Row);
Vue.component("column", Column);
Vue.component("form-view-item", FormViewItem);
Vue.component("form-group", FormGroup);
Vue.component("input-text", InputText);
Vue.component("error", ErrorMessage);
Vue.component("btn-primary", BtnPrimary);
Vue.component("checkbox", InputCheckbox);
Vue.use(VueMoment);
Vue.use(vueScrollTo, {
  container: "html",
  duration: 500,
  easing: "ease",
  offset: -150,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.use(VueMq, {
  breakpoints: {
    mobile: 759,
    tablet: 1031,
    desktop: 1351,
    wide: Infinity
  },
  defaultBreakpoint: 'wide' // customize this for SSR
});

Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

// Vue.use(VueGtag, {
//   config: { id: process.env.VUE_APP_ANALYTICS_KEY || 'XXXX' },
//   enable: process.env.NODE_ENV === 'production'
// }, router);

Vue.use(VueTransitions);

Vue.filter("capitalize", (value: any) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.mixin({
  computed: {
    $isMobile() { return this.$mq === "mobile" },
    $isTablet() { return this.$mq === "tablet" },
    $isDesktop() { return this.$mq === "desktop" },
  }
});

Vue.config.productionTip = process.env.NODE_ENV === "production";

new Vue({
  router,
  store,
  i18n,
  render: (h: any) => h(App)
}).$mount('#app');
