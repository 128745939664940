<style lang="scss">
    .vue-dialog.cguModal {

        text-align: center;

        .dialog-c-close {
            position: absolute;
            top: $gap / 3;
            right: $gap / 3;
            .icon {
                height: 30px;
                width: 30px;
                svg path {
                    fill: $secondary;
                }
            }
        }

        .xhr {
            margin-top: 10px;
            margin-bottom: 0 !important;
            div {
                width: 100px;
            }
        }

        .form-group {
            margin-bottom: $gap / 2;
        }

        p {
            text-align: center;
            font-size: 18px;
            color: $primary;
            font-weight: $thin;
            margin-bottom: $gap;

            b {
                font-weight: $bold;
            }
        }
        .infos {
            margin-top: $gap / 2;
        }

        .info {
            margin-top: $gap / 2;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: $bold;
                font-size: 18px;
            }

            .icon {
                height: 40px;
                width: 40px;
                border: 1px solid $primary;
                border-radius: 100%;
                margin-right: $gap / 2;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 20px;
                    height: 20px;
                    margin: 10px;
                }
            }
            &.blue {
                color: $primary;
                svg path {
                    fill: $primary;
                }
            }
            &.yellow {
                color: $secondary;
                svg path {
                    fill: white;
                }
                .icon {
                    border-color: $secondary;
                    background: $secondary;
                }
            }
        }
        .login {
            text-align: center;

            .btn {
                padding: $gap / 2 $gap;
            }
        }

        .action {
            color: white;
            flex: 2;
            text-align: center;


        }

        .text-item {
            .sub-title {
                font-size: 22px !important;
            }
            p {
                font-size: 18px !important;
            }
        }

        .text-wrapper {
            max-height:calc(100% - 200px);
            overflow: auto;
            p {
                text-align: left;
            }

            .third-title {
                color: $primary;
                margin-bottom: $gap / 3;
            }

            ul {
                margin-bottom: $gap;
                margin-top: -$gap / 2;
            }
            ul li {
                padding-left: $gap;
                font-size: 16px;
                color: $primary;
                font-weight: $thin;
            }
        }

        @include tabletMin() {
            .dialog-c-text {
                padding: $gap !important;
            }

            .dialog-c-title {
                font-size: 35px;
            }

        }
    }
</style>

<template>
    <modal :adaptive="true" height="100%" width="100%" :max-width="800" class="vue-dialog cguModal" :name="modalName" @before-open="beforeOpen" @opened="opened" @before-close="beforeClose" @closed="closed">
        <div class="dialog-content">
            <div class="dialog-c-title">Conditions générales d'utilisations</div>
            <xhr />
            <div class="dialog-c-text">
                <div class="text-wrapper">
                    <div class="text-item">
                        <sub-title>Informations légales, responsabilités et propriété intellectuelle</sub-title>
                        <p>Le site {{frontUrl}} que vous consultez actuellement est la propriété de : COSMO AV, 27 rue Maurice Gunsbourg – 94200 IVRY SUR SEINE – France</p>
                        <p>Les mentions « le site », « le site {{frontUrl}} » ou assimilées désignent aux présentes tant le site internet {{frontUrl}} que ses sous-domaines.</p>
                        <p>Le directeur de la publication est : Monsieur Pierre-Yves Toulot</p>
                        <p>Ce site est hébergé par la société : OVH, 2 rue Kellermann – 59100 ROUBAIX – France</p>
                        <p>Il a été conçu et développé par <a href="https://snarkfactory.fr/" target="_blank">snarkfactory.fr</a></p>
                    </div>
                    <div class="text-item">
                        <sub-title>Propriété intellectuelle</sub-title>
                        <p>La société COSMO AV détient, se réserve et conserve tous les droits de propriété, notamment intellectuelles et industrielles, y compris les droits de reproduction sur le présent site et les éléments qu’il contient. En conséquence et notamment toute reproduction partielle ou totale du présent site et des éléments qu’il contient est strictement interdite sans autorisation écrite et préalable de COSMO AV. Les marques, brevets, logos et tous autres signes distinctifs contenus sur ce site sont déposés et font l’objet d’une autorisation d’utilisation. Aucun droit ou licence ne saurait être attribué sur l’un quelconque de ces éléments sans une autorisation écrite préalable de COSMO AV ou du tiers détenteur des droits. L’utilisateur d’un site ne peut mettre en place un lien hypertexte en direction de celui-ci sans l’autorisation expresse préalable de la société « COSMO AV ».</p>
                    </div>
                    <div class="text-item">
                        <sub-title>Exclusion de garantie et de responsabilité</sub-title>
                        <p>L’utilisateur du site web « {{frontUrl}} » reconnaît avoir pris connaissance des présentes conditions d’utilisation du site et s’engage à les respecter.</p>
                        <p>COSMO AV ne saurait être tenue pour responsable des dommages directs ou indirects qui pourraient résulter de l’accès au site ou de l’utilisation du site et/ou de ces informations, y compris l’inaccessibilité, les pertes de données, détériorations, destructions ou virus qui pourraient affecter l’équipement informatique de l’utilisateur et/ou de la présence de virus sur son site.</p>
                        <p>Bien que COSMO AV s’efforce de fournir un contenu fiable sur ce site, elle ne garantit pas que son contenu soit exempt d’inexactitudes ou d’omissions et ne saurait être tenue pour responsable des erreurs ou omissions, d’une absence de disponibilité des informations et des services. COSMO AV se réserve à tout moment et sans préavis le droit d’apporter des améliorations et/ou des modifications au contenu de son site.</p>
                        <p>En conséquence, l’utilisateur reconnaît se servir de ses informations sous sa responsabilité exclusive.</p>
                    </div>
                    <div class="text-item">
                        <sub-title>Liens vers d’autres sites</sub-title>
                        <p>Le site peut inclure des liens vers d’autres sites ou d’autres sources Internet. Dans la mesure où COSMO AV ne peut contrôler ces sites et ces sources externes, elle ne peut être tenue pour responsable de la mise à disposition de ces sites et sources externes, et décline ainsi toute responsabilité quant aux contenus, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes. Ces liens sont proposés aux utilisateurs du site en tant que service. La décision d’activer les liens vous appartient exclusivement.</p>
                        <p>Nous vous rappelons que les sites non-affiliés sont soumis à leurs propres conditions d’utilisation.</p>
                    </div>
                    <div class="text-item">
                        <sub-title>Absence de portée contractuelle des informations présentées sur le site</sub-title>
                        <p>Les informations, illustrations et toutes données diffusées sur le présent site internet n’ont aucune portée contractuelle.</p>
                    </div>
                    <div class="text-item">
                        <sub-title>Informatique et libertés</sub-title>
                        <p>Conformément à l’article 32 de la loi n°78-17 « Informatique et Libertés » du 6 janvier 1978 modifiée, l’utilisateur du site est informé que la société COSMO AV dispose d’un traitement informatique pour la communication de ses actualités et newsletter. A cette fin, la société COSMO AV est amenée à enregistrer les informations recueillies concernant l’utilisateur du site. L’utilisateur du site peut exercer ses droits d’accès et de rectification aux données le concernant auprès de la société « COSMO AV », 27 rue Maurice Gunsbourg – 94200 IVRY SUR SEINE – France – Courriel : contact@cosmoav.com.</p>
                        <p>La marque COSMO AV, et son logo sont déposés et protégés.</p>
                        <div class="third-title">Collecte de l’information</div>
                        <p>Nous recueillons des informations lorsque vous vous inscrivez à notre newsletter. Les informations recueillies incluent votre nom, votre adresse e-mail, numéro de téléphone et pays d’origine.</p>
                        <p>En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels et votre matériel, et la page que vous demandez.</p>
                        <div class="third-title">Utilisation des informations</div>
                        <p>Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :</p>
                        <ul>
                            <li>- Améliorer notre site Web</li>
                            <li>- Vous contacter par e-mail</li>
                            <li>- Administrer un concours, une promotion, une enquête</li>
                            <li>- Divulgation à des tiers</li>
                        </ul>
                        <p>Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.</p>
                        <p>Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.</p>
                        <p>Les informations non-privées, cependant, peuvent être fournies à d’autres parties pour le marketing, la publicité, ou d’autres utilisations.</p>
                        <sub-title>Protection des données personnelles</sub-title>
                        <p>Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous protégeons vos informations en ligne et hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.</p>
                        <sub-title>Est-ce que nous utilisons des cookies ?</sub-title>
                        <p>Oui. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. En outre, nos cookies améliorent l’expérience d’utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.</p>
                        <sub-title>Se désabonner</sub-title>
                        <p>Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations, des nouvelles de l’entreprise de façon occasionnelle. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.</p>
                        <p>Vous pouvez demander la suppression ou la rectification de vos données en contactant communication@cosmoav.com</p>
                        <sub-title>Consentement</sub-title>
                        <p>En utilisant notre site, vous consentez à notre politique de confidentialité.</p>
                    </div>
                </div>
                <row>
                    <column class="login">
                        <btn-primary class="yellow" @click="hide">Retour</btn-primary>
                    </column>
                </row>
            </div>
            <div class="dialog-c-close" @click="hide">
                <icon icon="close"/>
            </div>
        </div>
    </modal>
</template>

<script>
    import icon from "@/components/icon.vue";
    import xhr from "@/components/xhr.vue";
    import inputInlineText from "@/components/forms/inputInlineText.vue";
    import {mapActions} from "vuex";

    export default {
        data() {
            return {
                frontUrl: window.location.origin,
                modalName: "cgu",
                loading: false,
                email: '',
                password: '',
                errors: {}
            };
        },
        components: {
            icon,
            xhr,
            inputInlineText
        },
        props: {

        },
        methods: {
            cancel() {
                this.$emit("cancel");
                this.$modal.hide(this.modalName)
            },
            validate() {
                this.$emit("submit", this.$data);
            },
            show() {
                this.$modal.show(this.modalName);
            },
            hide() {
                this.$modal.hide(this.modalName)
            },
            beforeOpen() {
                this.errors = {};
                this.email = "";
                this.password = "";
                this.$emit("beforeOpen", this.$data);
            },
            opened() {
                this.$emit("opened", this.$data);
            },
            beforeClose() {
                this.$emit("beforeClose", this.$data);
            },
            closed() {
                this.$emit("closed", this.$data);
            }
        },
        created() {
            this.show();
        }
    };
</script>