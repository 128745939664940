<script lang="ts">
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "xhr"
    })
    export default class xhr extends Vue {
    }
</script>
<template>
    <div class="xhr">
        <div />
    </div>
</template>
<style lang="scss">
    .xhr {
        margin: $gap 0;
        width: 100%;
        div {
            margin: 0 auto;
            width: 100%;
            max-width: 200px;
            border: 1px solid $yellow;
            height: 1px;
        }

        @include tabletMin() {
            margin: $biggap 0;
        }
    }
</style>