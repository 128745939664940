import Vue from 'vue';
import Vuex from 'vuex';
import query from "./query";
import actions from "./actions";
import {IRootState, IUser} from "@/types";

Vue.use(Vuex);

export default new Vuex.Store({
    state: <IRootState>{
        scrollActivated: false,
        loginVerified: false,
        isAuth: false,
        token: "",
        connectedUser: {}
    },
    mutations: {
        setScroll(state: IRootState, value: boolean) {
            state.scrollActivated = value;
        },
        setUser(state: IRootState, value: IUser) {
            state.connectedUser = value;
        },
        setLoginVerified(state: IRootState, value: boolean) {
            state.loginVerified = value;
        },
        setToken(state: IRootState, value: string) {
            state.token = value;
        },
        setIsAuth(state: IRootState, value: boolean) {
            state.isAuth = value;
        },
        logout(state: IRootState, value: boolean) {
            state.isAuth = false;
            state.token = '';
            state.connectedUser = <IUser>{};
            localStorage.removeItem('token');
        }
    },
    actions: {
    },
    modules: {
        query,
        actions
    }
});
