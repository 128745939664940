<script lang="ts">
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import inputInlineText from "@/components/forms/inputInlineText.vue";
    import {Action} from "vuex-class";
    import {StoreAction} from "@/types";

    @Component({
        name: "newsletter",
        components: {
            inputInlineText
        }
    })
    export default class newsletter extends Vue {
        loading: boolean = false;
        email: string = "";
        errors: any = {};
        success: string = "";

        @Action('actions/registerNewsletter') registerNewsletter!: StoreAction;

        validate() {
            if (!this.email) {
                this.errors.email = {slug: "required"};
                return false;
            }
            return {
                email: this.email
            };
        }

        _registerNewsletter() {
            this.errors = {};
            this.loading = true;
            const data = this.validate();
            if (data) {
                this.registerNewsletter(data)
                    .then((result: any) => {
                        // const data = result.data;
                        this.success = "Merci pour votre inscription.";
                        // setTimeout(() => {
                        //     this.success = "";
                        // }, 5000);
                    })
                    .catch((err: any) => {
                        this.errors.email = {slug: 'notValidEmail'};
                        this.$forceUpdate();
                        console.log(err);
                    })
                    .then((_: any) => {
                        this.loading = false;
                    })
            } else {
                this.loading = false;
            }
        }
    }
</script>
<template>
    <div class="newsletter">
        <row>
            <column t="6">
                <div class="flex-wrapper">
                    <div class="icon">
                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                             viewBox="0 0 40 29.32" xml:space="preserve">
                            <path d="M39.33,2.33c0-1.29-1.05-2.33-2.34-2.33H3.01C2.04,0,1.16,0.61,0.82,1.52C0.73,1.77,0.69,2.03,0.7,2.32
                                C0.25,2.76,0,3.34,0,3.97v20.97c0,0.68,0.31,1.33,0.84,1.77c0.09,0.5,0.33,0.96,0.7,1.3c0.91,0.85,2.09,1.31,3.33,1.31h30.25
                                c1.24,0,2.42-0.47,3.33-1.31c0.37-0.34,0.61-0.8,0.69-1.3c0.54-0.44,0.85-1.09,0.85-1.78V3.96C40,3.35,39.76,2.77,39.33,2.33z
                                 M26.53,14.37l10.64-9.12V23.5L26.53,14.37z M23.94,12.81c-0.11,0.1-0.21,0.2-0.23,0.25L20,16.24l-3.64-3.11
                                c-0.09-0.11-0.18-0.22-0.3-0.32L4.58,2.83h30.83L23.94,12.81z M15.88,16.48l3.79,3.31c0.19,0.16,0.47,0.16,0.66,0l3.79-3.31
                                l11.65,9.9c-0.21,0.07-0.42,0.11-0.64,0.11H4.87c-0.22,0-0.44-0.04-0.65-0.11L15.88,16.48z M24.28,15.97L24.28,15.97L24.28,15.97
                                L24.28,15.97z M13.47,14.37L2.83,23.49V5.25L13.47,14.37z"/>
                        </svg>

                    </div>
                    <div class="text">{{$t('newsletter.title')}}</div>
                </div>
            </column>
            <column t="6">
                <div class="flex-wrapper nobottom">
                    <input-inline-text v-model="email"
                                       name="email"
                                       right
                                       blue
                                       :error="errors.email"
                                       :placeholder="$t('newsletter.placeholder')"
                                       :actionLabel="$t('newsletter.action')"
                                       @submit="_registerNewsletter" />
                </div>
                <collapse-transition mode="out-in">
                    <p class="success" v-if="success">{{success}}</p>
                </collapse-transition>
            </column>
        </row>
    </div>
</template>
<style lang="scss">
    .newsletter {
        background: $yellow;
        padding: $gap 0;
        color: white;

        .success {
            text-align: center;
        }

        .flex-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: $gap;

            &.nobottom {
                margin-bottom: 0;

                .form-group {
                    margin-bottom: $gap / 4;

                    width: 100%;
                    .inputInlineText {
                        width: 100%;

                        .input-flex-wrapper {
                            width: 100%;
                            .action, input {
                                width: 100%;
                            }

                            input {
                                flex: 2;
                            }
                            .action {
                                flex: 1;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .icon {
            height: 40px;
            width: 40px;
            margin: 0 $gap / 2;
            svg {
                fill: white;
            }
        }

        .text {
            font-size: 24px;
            font-weight: $bold;
        }

        @include tabletMin() {
            .flex-wrapper {
                margin-bottom: 0;
            }
        }
    }
</style>