<style lang="scss">
    .form-group {
    }
</style>

<template>
    <div :class="`form-group${error ? ' error' : ''}`">
        <slot></slot>
        <collapse-transition mode="out-in">
            <div v-if="error && !noError" >
                <error :noScroll="true" :globalError="globalError" :error="error" />
            </div>
        </collapse-transition>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";

    export default {
        data() {
            return {};
        },
        props: {
            w: {
                type: String,
                default: ''
            },
            d: {
                type: String,
                default: ''
            },
            t: {
                type: String,
                default: ''
            },
            m: {
                type: String,
                default: '12'
            },
            error: {
            },
            globalError: {
                type: Boolean
            },
            noScroll: Boolean,
            noError: Boolean
        },
        computed: {},
        components: {},
        methods: {},
        beforeMount() {},
        mounted() {},
        created() {}
    };
</script>
