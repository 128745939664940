<style lang="scss">
    .inputText {
        width: 100%;
        &.error {
            input {
                border-color: red;
            }
            label {
                color: red;
            }
        }
        .sublabel {
            margin-bottom: $gap / 3;
            font-size: 12px;
        }

        .label {
            margin-bottom: $gap / 3;
            color: $primary;
            font-weight: $thin;
            display: block;
        }

        .input-text {
            width: 100%;
            height: 30px;
            border: 2px solid $secondary;
            color: $primary;
            padding-left: $gap / 2;
            &:focus {
                border-color: $primary;
            }
        }

        @include tabletMin() {
            .input-text {
                height: 40px;
                font-size: 18px;
            }
        }
    }
</style>

<template>
    <form-group :error="error">
        <div :class="`inputText ${error ? 'error' : ''}`">
            <label class="label" v-show="label" :for="name">{{label}}</label>
            <input
                v-if="pattern"
                :id="name"
                v-model="hasMask ? displayedValue : cur_value"
                @input="$emit('input', cur_value)"
                :maxlength="length"
                :name="name"
                :type="type"
                :placeholder="placeholder"
                :readonly="readonly"
                :value="cur_value"
                v-mask="pattern || ''"
                :required="required"
                :min="min"
                :max="max"
                :step="step"
                :disabled="disabled"
                @keydown.enter="$emit('enter')"
                class="input-text"
            >
            <input
                v-else
                :id="name"
                v-model="hasMask ? displayedValue : cur_value"
                @input="$emit('input', cur_value)"
                :maxlength="length"
                :name="name"
                :type="type"
                :placeholder="placeholder"
                :readonly="readonly"
                :value="cur_value"
                :required="required"
                :min="min"
                :max="max"
                :step="step"
                :disabled="disabled"
                @keydown.enter="$emit('enter')"
                class="input-text"
            >
        </div>
    </form-group>
</template>

<script>
    export default {
        $_veeValidate: {
            value() {
                return this.cur_value || this.selectValue;
            },
            name() {
                return this.label;
            }
        },
        data() {
            return {
                cur_value: "",
                select_value: "",
                displayedValue: "",
            };
        },
        watch: {
            select_value(n) {
                this.$emit("selectChange", n);
            },
            value(n) {
                this.cur_value = n;
                if (this.hasMask) {
                    this.displayedValue = "~ " + n + "t";
                }
            }
        },
        props: {
            hasMask: Boolean,
            mask: String,
            min: {},
            max: {},
            label: {},
            name: {},
            value: {},
            type: {},
            step: {},
            placeholder: {},
            postfix: {},
            disabled: Boolean,
            withSelect: { type: Boolean },
            items: {},
            innerText: {},
            selectItems: { type: Array },
            readonly: { type: Boolean },
            selectValue: {},
            required: { type: Boolean },
            pattern: {},
            length: {},
            error: { type: Object },
            canEmptyField: {type: Boolean},
            sublabel: {}
        },
        computed: {},
        components: {},
        methods: {
            emptyField() {
                this.cur_value = '';
                this.$emit('input', this.cur_value);
            }
        },
        beforeMount() {},
        updated() {
            if (typeof this.value !== "undefined") {
                this.cur_value = this.value;
            }
            if (typeof this.selectValue !== "undefined") {
                if (this.select_value === this.selectValue) {
                    this.$emit("selectChange", this.selectValue);
                }
                this.select_value = this.selectValue;
            }
            if (this.hasMask) {
                this.displayedValue = "~ " + this.cur_value + "t";
            }
        },
        mounted() {
            if (typeof this.value !== "undefined") {
                this.cur_value = this.value;
            }
            if (typeof this.selectValue !== "undefined") {
                if (this.select_value === this.selectValue) {
                    this.$emit("selectChange", this.selectValue);
                }
                this.select_value = this.selectValue;
            }
            if (this.hasMask) {
                this.displayedValue = "~ " + this.cur_value + "t";
            }
        },
        created() {}
    };
</script>
