"use strict";

import axios, {AxiosRequestConfig} from "axios";
import {ActionContext} from "vuex";
import {IRootState} from "@/types";

interface IConfig {
    url: string,
    data?: any,
    params?: object,
    headers?: object,
    responseType?: string,
    method: string
}

interface IError {
    errors: any,
    formatedErrors: any
}

function query(cnf: IConfig) {
    cnf.url = process.env.VUE_APP_API_URL + cnf.url;
    return axios(<AxiosRequestConfig>cnf);
}

const actions = {
    send({ commit, rootState } : ActionContext<IRootState, IRootState>, { to, method, data = {}, params = {}, headers = {}, responseType } : { to: string, method: string, data: any, params: any, headers: any, responseType: string }) {
        return new Promise((resolve: any, reject: any) => {
            const config:IConfig = { method, url: to };

            headers["Authorization"] = data.token || rootState.token;

            data && (config.data = data);
            params && (config.params = params);
            headers && (config.headers = headers);
            responseType && (config.responseType = responseType);

            query(config)
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((err: any) => {
                    const realData = err.response && err.response.data;
                    let errors: IError;

                    if (realData && Array.isArray(realData) && realData.length) {
                        errors = {errors: realData, formatedErrors: {}};
                        realData.forEach((err: any) => {
                            if (err.slug) {
                                if (typeof err.field === "string") {
                                    errors.formatedErrors[err.field] = {};
                                    errors.formatedErrors[err.field].slug = err.slug;
                                    errors.formatedErrors[err.field].action = to;
                                    errors.formatedErrors[err.field].field = err.field;
                                } else {
                                    if (!errors.formatedErrors._global) {
                                        errors.formatedErrors._global = [];
                                    }
                                    errors.formatedErrors._global.push({
                                        slug: err.slug,
                                        action: to
                                    });
                                }
                            }
                        });
                    }

                    // @ts-ignore
                    reject(errors || err);
                });
        });
    }
};

export default {
    actions
};
