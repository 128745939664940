<style lang="scss">
    .row {
    }
</style>

<template>
    <div class="row">
        <slot></slot>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";

    export default {
        data() {
            return {};
        },
        computed: {},
        components: {},
        methods: {},
        beforeMount() {},
        mounted() {},
        created() {}
    };
</script>
