<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import logo from "@/components/logo.vue";
    import partners from "@/components/partners.vue";
    import newsletter from "@/components/newsletter.vue";
    import cguModal from "@/components/modals/cguModal.vue";

    @Component({
        name: "xfooter",
        components: {
            logo,
            partners,
            newsletter,
            cguModal
        }
    })
    export default class xfooter extends Vue {
        showCgu: boolean = false;

        openCgu() {
            this.showCgu = true;
            this.$nextTick(() => {
                this.$modal.show('cgu');
            });
        }

        openLegals() {
            this.showCgu = true;
            this.$nextTick(() => {
                this.$modal.show('cgu');
            });
        }
    }
</script>
<template>
    <div class="xfooter">
        <div class="top container">
            <partners />
            <newsletter />
        </div>
        <div class="bottom container">
            <row v-if="$isMobile">
                <column class="center" m="12">
                    <logo white />
                </column>
                <column class="center" m="12">
                    <div class="links"><span @click="openCgu">{{$t('footer.cgu')}}</span> / <span @click="openLegals">{{$t('footer.legals')}}</span></div>
                </column>
                <column class="center" m="12">
                    <div class="copyright">COSMO AV &COPY; - {{(new Date()).getFullYear()}}</div>
                </column>
            </row>
            <template v-else>
                <div class="copyright">COSMO AV &COPY; - {{(new Date()).getFullYear()}}</div>
                <logo white />
                <div class="links"><link-like @click="openCgu">{{$t('footer.cgu')}}</link-like> / <link-like @click="openLegals">{{$t('footer.legals')}}</link-like></div>
            </template>
        </div>
        <cgu-modal @closed="showCgu = false"/>
    </div>
</template>
<style lang="scss">
    .xfooter {
        @include mobile() {
            .top {
                margin: 0;
                padding: 0;
            }
        }
        .bottom {
            padding-top: $gap * 1.5;
            padding-bottom: $gap * 1.5;
            display: flex;
            align-items: center;
            color: white;
            background: $primary;

            .center {
                text-align: center;
            }

            .logo {
                height: 60px;
                margin-bottom: $gap;
            }

            .copyright {
                margin-top: $gap / 2;
                font-size: 12px;
            }
        }

        .links {
            cursor: pointer;
            color: white;
            .link-like {
                text-decoration: none;
                color: white;
            }
        }

        @include tabletMin() {
            .top {
            }
            .bottom {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                .row  {
                    width: 100%;
                }
                .logo {
                    margin-bottom: 0;
                }
                .copyright {
                    margin-top: 0;
                }
            }
        }
    }
</style>