<script lang="ts">
import Vue from "vue";
import xheader from "@/components/xheader.vue";
import xfooter from "@/components/xfooter.vue";
import {Component, Watch} from "vue-property-decorator";
import {Action, Mutation, State} from "vuex-class";
import {StoreAction} from "@/types";

@Component({
	name: "App",
	components: {
		xheader,
		xfooter,
	}
})
export default class App extends Vue {
	reachedTop: boolean = false;
	opacityValue: number = 0;

	@State('loginVerified') loginVerified!: boolean;
	@Mutation('setLoginVerified') setLoginVerified!: any;
	@Action('actions/reauthenticate') reauthenticate!: StoreAction;

	@Watch("$route.name")
	onChange(route: any) {
		if (this.isScrolled()) {
			this.opacityValue = this.isScrolled() ? window.scrollY / 100 : 1;
			this.reachedTop = this.isScrolled();
		} else {
			this.reachedTop = false;
		}
	}

	get isHome() {
		return true;
	}

	isScrolled() {
		return this.isHome && window.scrollY < 100;
	}

	scrolled() {
		this.opacityValue = this.isScrolled() ? window.scrollY / 100 : 1;
		this.reachedTop = this.isScrolled();
	}

	beforeMount() {
		if (!this.loginVerified) {
			const token = localStorage.getItem('token');

			if (token) {
				this.reauthenticate({token})
					.then((result: any) => {
						console.log(result);
					})
					.catch((err: any) => {
						console.log(err);
						this.setLoginVerified(true);
					})
			} else {
				this.setLoginVerified(true);
			}
		}
	}

	mounted() {
		if (document) {
			document.onscroll = this.scrolled;
			this.reachedTop = this.isScrolled();
		}
	}
}
</script>

<template>
	<div id="app" v-if="loginVerified">
		<xheader :reachedTop="reachedTop" :opacityValue="opacityValue" />
		<fade-transition mode="out-in">
			<router-view :key="$route.fullPath"/>
		</fade-transition>
		<xfooter />
	</div>
</template>

<style lang="scss">
@import "@/sass/main.scss";

#app {
	background: $grey;
}
</style>
