<script lang="ts">
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "subTitle"
    })
    export default class subTitle extends Vue {
    }
</script>
<template>
    <div class="sub-title">
        <slot></slot>
    </div>
</template>
<style lang="scss">
    .sub-title {
        font-size: 22px;
        color: $primary;
        font-weight:$bold;
        margin-bottom: $gap / 2;

        @include tabletMin() {
            font-size: 25px;
            margin-bottom: $biggap / 2;
        }
    }
</style>