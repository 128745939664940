<script lang="ts">
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "logo",
        props: {
            white: Boolean
        }
    })
    export default class logo extends Vue {
    }
</script>
<template>
    <router-link :to="{name: 'home'}">
        <img v-if="white" class="logo" src="/static/imgs/logo-white.png" alt="">
        <img v-else class="logo" src="/static/imgs/logo.png" alt="">
    </router-link>
</template>
<style lang="scss">
    .logo {
    }
</style>