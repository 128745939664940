const config = require(process.env.VUE_APP_CONFIGURATION_PATH).default;

module.exports = {
    projectName: config.name,
    header: {
        login: "Connexion"
    },
    footer: {
        cgu: "CGU",
        legals: "Mentions légales"
    },
    event: {
        image: config.home.mainBackground,
        mainTitle: config.home.mainTitle,
        subTitle: config.home.subTitle,
        description: config.home.description,
        aboutImage: config.home.aboutImage,

        participationImage: config.participationBackground? config.participationBackground:config.home.mainBackground,
        participationDescription: config.participationDescription,
        participationCaracteristiques: config.participationCaracteristiques
    },
    page: {
        home: {
            action: "Je participe !",
            about: {
                title: "En savoir plus",
                description: config.home.about,
                image: config.home.aboutImage
            }
        },
        participate: {
            limitationDate: config.DATE,
            minorAuthorization: config.minorAuthorization? "true":"false",
            authorization: config.minorAuthorizationFile,
            imageRights: config.imageRights? "true":"false",
            action: "Commencer",
            configuration: {
                type: config.type,
                models: config.models? "true":"false"
            },
            models: {
                title: "Les modèles à télécharger",
                action: "Télécharger",
                left: {
                    image: config.mainModel,
                    description: config.modelDescription
                },
                right: {
                    images: config.models
                }
            },
            startTitle: "C'est parti",
            media: {
                legend: {
                    title: "Caractéristiques"
                },
                actions: {
                    edit: "Modifier",
                    remove: "Supprimer"
                }
            },
            register: {
                title: "Formulaire d'inscription",
                action: "Valider ma participation",
                minor: {
                    title: "Vous êtes mineur",
                    description: "Veuillez télécharger le document de cession de droit ci-dessous et le faire signer par l’un de vos parents ou votre représentant légal.",
                },
                minorActions: {
                    originalDocument: "Document à télécharger",
                    addDocument: "Envoyer le document signé",
                    myDocument: "Mon document signé",
                    editDocument: "Modifier le document"
                },
                edit: {
                    title: "Ma participation",
                    action: "Modifier mes données",
                    remove: "Supprimer ma participation"
                },
                cgu: "CGU",
                about: "En savoir plus",
                user: {
                    firstname: "Votre prénom",
                    lastname: "Votre nom",
                    email: "Votre email",
                    birthdate: "Votre date de naissance",
                    cgu: "En cochant cette case, j'accepte les",
                    rights: {
                        video: "En cochant cette case, je cède mes droits à l'image pour ce spectacle et cette vidéo.",
                        image: "En cochant cette case, je cède mes droits à l'image pour ce spectacle et cette image.",
                    }
                },
                thanks: {

                }
            }
        }
    },
    partners: config.partners? config.partners.map(l => {return {name: l.name, image: l.image};}):[],
    whitePartners: config.partners? config.partners.map(l => {return {name: l.name, image: l.whiteImage};}):[],

    newsletter: {
        title: "Abonnez vous à la newsletter de Cosmo AV",
        placeholder: "Inscrivez votre email ici",
        action: "Je m'inscris"
    }
};