<style lang="scss">
    .vue-dialog {

        text-align: center;

        .dialog-c-close {
            position: absolute;
            top: $gap / 3;
            right: $gap / 3;
            .icon {
                height: 30px;
                width: 30px;
                svg path {
                    fill: $secondary;
                }
            }
        }

        .xhr {
            margin-top: 10px;
            margin-bottom: 0 !important;
            div {
                width: 100px;
            }
        }

        .form-group {
            margin-bottom: $gap / 2;
        }

        p {
            text-align: center;
            font-size: 18px;
            color: $primary;
            font-weight: $thin;
            margin-bottom: $gap;

            b {
                font-weight: $bold;
            }
        }
        .infos {
            margin-top: $gap / 2;
        }

        .info {
            margin-top: $gap / 2;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: $bold;
                font-size: 18px;
            }

            .icon {
                height: 40px;
                width: 40px;
                border: 1px solid $primary;
                border-radius: 100%;
                margin-right: $gap / 2;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 20px;
                    height: 20px;
                    margin: 10px;
                }
            }
            &.blue {
                color: $primary;
                svg path {
                    fill: $primary;
                }
            }
            &.yellow {
                color: $secondary;
                svg path {
                    fill: white;
                }
                .icon {
                    border-color: $secondary;
                    background: $secondary;
                }
            }
        }
        .login {
            text-align: center;
        }

        .action {
            color: white;
            flex: 2;
            text-align: center;


        }
    }

</style>

<template>
    <modal :adaptive="true" height="auto" width="100%" :max-width="800" class="vue-dialog" :name="modalName" @before-open="beforeOpen" @opened="opened" @before-close="beforeClose" @closed="closed">
        <div class="dialog-content">
            <div class="dialog-c-title">Renouveler mon mot de passe</div>
            <xhr />
            <div class="dialog-c-text" v-if="!success">
                <row>
                    <column>
                        <p>Veuillez entrer l'adresse email avec laquelle vous avez participé afin de recevoir un email contenant un lien de renouvellement.</p>
                        <p>Si vous n’avez pas encore participé, rendez-vous sur le <link-like @click="redirectParticipate">formulaire de participation</link-like>.</p>
                    </column>
                </row>
                <row class="infos">
                    <column>
                        <input-text label="Mon email" :error="errors.email" v-model="email"/>
                        <link-like class="subLinkLike" @click="$emit('remember')">Finalement je me souviens de mon mot de passe</link-like>
                    </column>
                </row>
                <row>
                    <column class="login">
                        <transition name="fastfade">
                            <pulse v-if="loading" />
                        </transition>
                        <btn-primary class="yellow" @click="_sendForgot">Renouvelez</btn-primary>
                    </column>
                </row>
            </div>
            <div class="dialog-c-text" v-else>
                <p>Vous allez recevoir dans quelques instant un email concernant le renouvelement de votre mot de passe.</p>
            </div>
            <div class="dialog-c-close" @click="hide">
                <icon icon="close"/>
            </div>
        </div>
    </modal>
</template>

<script>
    import icon from "@/components/icon.vue";
    import xhr from "@/components/xhr.vue";
    import inputInlineText from "@/components/forms/inputInlineText.vue";
    import {mapActions} from "vuex";

    export default {
        data() {
            return {
                modalName: "forgot",
                loading: false,
                email: '',
                password: '',
                errors: {},
                success: false,
            };
        },
        components: {
            icon,
            xhr,
            inputInlineText
        },
        props: {

        },
        methods: {
            ...mapActions('actions', ['sendForgot']),
            validData() {
                if (!this.email) {
                    this.errors.email = {slug: "required"};
                }
                return !Object.keys(this.errors).length;
            },

            redirectParticipate() {
                this.hide();
                this.$router.push({name: 'participate'});
            },
            _sendForgot() {
                this.loading = true;
                this.errors = {};
                if (this.validData()) {
                    this.sendForgot({
                        email: this.email,
                    })
                        .then((result) => {
                            this.success = true;
                        })
                        .catch((err) => {
                            this.errors.email = {slug: "notValidResetEmail"};
                        })
                        .then((_) => {
                            this.loading = false;
                        })
                } else {
                    this.loading = false;
                }
            },
            cancel() {
                this.$emit("cancel");
                this.$modal.hide(this.modalName)
            },
            validate() {
                this.$emit("submit", this.$data);
            },
            show() {
                this.$modal.show(this.modalName);
            },
            hide() {
                this.$modal.hide(this.modalName)
            },
            beforeOpen() {
                this.errors = {};
                this.email = "";
                this.password = "";
                this.$emit("beforeOpen", this.$data);
            },
            opened() {
                this.$emit("opened", this.$data);
            },
            beforeClose() {
                this.$emit("beforeClose", this.$data);
            },
            closed() {
                this.$emit("closed", this.$data);
            }
        },
        created() {
            this.show();
        }
    };
</script>