<style lang="scss">
    .inputCheckbox {
        display: flex;
        align-items: center;
        label.label {
            margin-bottom: 0;
        }
    }
</style>

<template>
        <div :class="`inputCheckbox ${error ? 'error' : ''}`">
            <input
                :id="name"
                v-model="cur_value"
                @change="$emit('input', cur_value)"
                :name="name"
                :type="'checkbox'"
                :disabled="readonly"
                :checked="cur_value"
                :required="required"
                class="checkbox"
            >
            <label class="label nobold" v-show="label" :for="name" v-html="label" />
        </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";

    export default {
        $_veeValidate: {
            value() {
                return this.cur_value || this.selectValue;
            },
            name() {
                return this.label;
            }
        },
        data() {
            return {
                cur_value: "",
                select_value: ""
            };
        },
        watch: {
            value(n) {
                this.cur_value = n;
            }
        },
        props: {
            min: {},
            max: {},
            label: {},
            name: {},
            value: {},
            type: {},
            placeholder: {},
            postfix: {},
            withSelect: { type: Boolean },
            items: {},
            innerText: {},
            selectItems: { type: Array },
            readonly: { type: Boolean },
            selectValue: {},
            required: { type: Boolean },
            pattern: {},
            length: {},
            error: { type: Object },
            canEmptyField: {type: Boolean},
            sublabel: {}
        },
        computed: {},
        components: {},
        methods: {
            emptyField() {
                this.cur_value = '';
                this.$emit('input', this.cur_value);
            }
        },
        beforeMount() {},
        updated() {
            if (typeof this.value !== "undefined") {
                this.cur_value = this.value;
            }
        },
        mounted() {
            if (typeof this.value !== "undefined") {
                this.cur_value = this.value;
            }
        },
        created() {}
    };
</script>
